.SubmitSong {
    max-width: 1000px;
    /* background: rgba(0,0,0,0.5); */
    /* margin-top: 5em; */
    border-left: 3px solid rgba(0,0,0,0.5);
    border-right: 3px solid rgba(0,0,0,0.5);
    border-bottom: 3px solid rgba(0,0,0,0.5);
    background: rgba(0,0,0,0.5);
    /* border-radius: 1em; */
    font-size: 0.9em;
    margin: 5.5em auto 0 auto;
    display: flex;
    flex-flow: column nowrap;
    height: calc(100vh - 5.5em);
}

.SubmitSong > form > h2 {
    /* display: block;
    border: 1px solid red; */
    text-align: center;
    /* color: white; */
}
.SubmitSong  > h4 {
    /* display: block;
    border: 1px solid red; */
    text-align: center;
    /* color: white; */
}

.SubmitSong__Formbox {
    display: flex;
    flex-flow: column nowrap;
    /* justify-content: center; */
    align-items: center;
    /* border: 1px solid red; */
}

.SubmitSong__Formbox__Button {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    margin: 0.5em 0.3em;
    background: #7c61f7;
    border: 2px solid #7c61f7;
    border-radius: 0.2em;
    display: block;
    padding: 0.6em 2em;
    text-decoration: none;
    color: white;
    font-size: 1.1em;
    cursor: pointer;
   
}

.SubmitSong__Formbox__Button:hover {
    background: rgb(238, 46, 255);
    border: 2px solid rgb(209, 43, 224);
    transition: 0.1s ease;

}

.SubmitSong__SubmitInfo {
    width: 40em;
    color: white;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    padding: 0 0 1em 1em;
    background: rgb(67, 158, 248);
    border-radius: 0.5em;
    margin: 0.4em auto;
    transition: 0.5s ease;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border: 1px solid red; */
    align-self: center;
}

.SubmitSong__SubmitInfo__List {
    /* float: left; */
    padding-top: 1em;
    /* border: 1px solid red; */
    /* padding: 0; */
    
}

ul {
    padding-left: 1.1em;
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    /* pa */
    /* margin-left: 0px; */
}
li {
    padding-left: 0px;
    margin-left: 0px;
}

.SubmitSong__SubmitInfo__exit {

    line-height: 0.8em;
    padding-right: 0.07em;

    font-size: 2.5em;
    cursor: pointer;
    /* vertical-align: top; */
}

.SubmitSong__SubmitSuccess {
    width: 40em;
    color: white;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    /* padding: 1em; */
    padding: 0 0 1em 1em;
    background: rgb(5, 175, 5);
    border-radius: 0.5em;
    margin: 0.4em auto;
    transition: 0.5s ease;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-self: center;
}
.SubmitSong__SubmitSuccess__Message {
    /* float: left; */
    /* border: 1px solid red; */
    /* padding: 0; */
    padding-top: 1em;
    
}

.UploadProgress {
    width: 40em;
    /* margin: 8em auto 0 auto; */
    background: #7c61f7;
    padding: 0.5em;
    border-radius: 0.5em;
    /* z-index: 4;
    top: 33vh;
    position: absolute; */

    margin: 0 auto;

    /* left: 13em; */
    /* margin-left: -50px; */
    /* top: 50%; */
    /* margin-top: -50px; */
    /* margin: 0 auto; */
    /* left: calc(21em); */
}

.SubmitSong__SubmitSuccess__exit {
    line-height: 0.8em;
    padding-right: 0.07em;

    font-size: 2.5em;
    cursor: pointer;
    /* vertical-align: top; */
}

.veil {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    /* background: rgba(0,0,0,0.1); */
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

.MuiFilledInput-root {
    background: white !important;
  }

  .UserFormFlex {
      display: flex;
      flex-flow: row nowrap;
      
  }