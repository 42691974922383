@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  /* text-align: center; */
 
}

body {
  background: url(../../static/media/blurryphones.83261163.jpg);
  background-position: -600px 00;
  background-size: auto 100%;
  background-attachment: fixed;
  color: #f1f1f1;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  /* background-color: black; */

}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1f1f1f; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


/************FORMERLY ADMIN.CSS */
* {
  box-sizing: border-box;
}

.AdminContainer {
  /* max-width: 1000px; */
  margin: 0 auto;
  background: #1f1f1f;
  margin-top: 5em;
  font-size: 1em;
  min-height: calc(100vh - 5em);
  color: black;
  display: flex;
  flex-flow: row nowrap;
}

.AdminMenuBar {
  max-width: 300px;
  width: 25vw;
  background: #212529;
  min-height: calc(100vh - 5em);
  color: #f1f1f1;
  border-right: 3px solid rgba(0,0,0,0.5)
  /* border: 1px solid red; */
}

.AdminMenuBar__Header {
  margin-top: 1em;
  /* text-align: center; */
  /* background: rgb(90, 89, 89); */
  /* font-family: Arial, Helvetica, sans-serif; */
  background: rgba(0,0,0,0.4);
  text-transform: uppercase;
  color: #ccc;
  font-weight: bold;
  border-top: 4px solid rgba(0,0,0,0.5);
  /* border-left: 4px solid rgba(0,0,0,0.5);
  border-right: 4px solid rgba(0,0,0,0.5); */
  /* border-bottom: 4px solid rgba(0,0,0,0.5); */
  font-size: 0.7em;
  padding: 1em;
  /* border: 1px solid red; */
}

.AdminMenuBar__Link {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  /* font-weight: bold; */
  background: rgba(0,0,0,0.1);
  font-size: 1em;
  padding: 1em 1em;
  border-left: 4px solid #ccc;

}

.AdminMenuBar__Link:hover {
  background: purple;
  transition: 0.3s ease;
  border-left: 4px solid rgb(90, 1, 90);
  padding-left: 20px;
  cursor: pointer;
  /* font-weight: bold; */

}

.AdminContainer > h3 {
  color: #f1f1f1;
  text-align: center;
  border: 1px solid red;
}

.Nope {
  text-align: center;
  margin-top: 5.5em;
}

.AdminMenuBar__Link > a {
  text-decoration: none;
  color: inherit;
}

.rc-slider-track {
    background: linear-gradient(to right, #ff3019 ,#cf0404);
}
.rc-slider-handle {
  background-color: #8e0000 !important ;
}

/*******************************/
* {
    box-sizing: border-box;
}

.Navbar {
    width: 100%;
    position: fixed;
    top: 0;
    background: rgba(0,0,0,0.85);
    min-height: 5em;
    max-height: 5em;
    display: flex;
    flex-flow: row nowrap;
    border-bottom: 3px solid rgba(128,0,128, 0.5);

    /* border: 5px solid red; */

}

.Navbar__Flexbox__Left {
    display: flex;
    flex-flow: row nowrap;
    height: 5em;
    /* width: 100%; */
    align-items: center;
    justify-content: flex-start;
    /* align-content: center; */
    /* align-content: center; */

}


.Navbar__Flexbox__Left__Brand {
    max-height: 5em;
    margin-left: 2em;

}
.Navbar__Flexbox__Left__Brand__img {
    max-height: 5em;
    /* height: 3em; */
}

.Navbar__Flexbox__Left__Item {
    /* max-height: 100%; */
    margin: 0 1em;
    font-size: 1.1em;
    /* font-weight: 700; */
}

.Navbar__Flexbox__Left__Item > a {
    color: lightblue;
    text-decoration: none;
}

.Navbar__Flexbox__Right {
    display: flex;
    flex-flow: row nowrap;
    height: 5em;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
}

.Navbar__Flexbox__Right__Item {

    margin: 0 0.3em;
    font-size: 1.1em;

}

.Navbar__Flexbox__Right__Item__Button {
    margin: 0 0.3em;
    background: #7c61f7;
    border-radius: 0.2em;
    display: block;
    padding: 0.4em 0.5em;
    text-decoration: none;
    color: white;
}

.Navbar__Flexbox__Right__Item > a {
    color: lightblue;
    text-decoration: none;

}

.Navbar__Flexbox__Right__Item > a:hover {
    color: rgb(238, 46, 255);
    /* transition: 0.1s ease; */


}


.Navbar__Flexbox__Right__Item__Button > a {
    /* color: lightblue; */
    /* padding: 0.4em 0.5em; */
    color: white;
    text-decoration: none;
}

.Navbar__Flexbox__Right__Item__Button:hover {
    /* color: lightblue; */
    /* padding: 0.4em 0.5em; */

    background: rgb(238, 46, 255);
    transition: 0.1s ease;

}
.SongDisplay {
    max-width: 1000px;
    /* background: rgba(0,0,0,0.5); */
   
    border-left: 3px solid rgba(0,0,0,0.5);
    border-right: 3px solid rgba(0,0,0,0.4);
    /* border-radius: 1em; */
    font-size: 0.9em;
    margin: 5.5em auto 0 auto;
    display: flex;
    flex-flow: column nowrap;
    /* animation-name: fadeIn;
    animation-duration: 0.5s; */
    /* transition: 0.5s ease; */
}
.SongDisplay__Item:nth-child(odd) {
    background: rgba(0,0,0,0.15);
}
.SongDisplay__Item:nth-child(even) {
    background: rgba(0,0,0,0.2);
}

.SongDisplay__Item {
    /* background: rgba(0,0,0,0.3); */
    display: flex;
    flex-flow: row nowrap;
    /* border-bottom: 1px solid #ccc; */
    /* background: rgba(0,0,0, 0.5); */
    align-items: center;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 0.1s;
            animation-duration: 0.1s;

}
.SongDisplay__Item:hover {
    background: rgba(0,0,0,0.5);
    /* transition: 0.1s ease; */

}



.SongDisplay__Item > * {
    /* background-image: linear-gradient(to right, rgba(255,255,255, 0.06) 0%,rgba(255,255,255, 0) 100%); */
    /* color: white; */


}

.SongDisplay__Item__PlayIcon {
    /* max-height: 0.5em; */
    color: purple;
    background: rgba(0,0,0,0.2);
    /* align-items: center; */
    /* padding-right: 0.3rem; */
    /* border: 1px solid red; */
   
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    
}


.SongDisplay__Item__PlayIcon > * {
    font-size: 1.4em;
    margin-top: .1em;
    /* align-content: center; */
}
.SongDisplay__Item__PlayIcon:hover {
    color: rgb(168, 8, 168);
    transition: 0.3s ease;
}


.SongDisplay__Item__PlayListAdd {
    /* max-height: 0.5em; */
    color: orange;
    background: rgba(0,0,0,0.2);
    /* align-items: center; */
    /* padding-right: 0.3rem; */
    /* border: 1px solid red; */
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-right: .2em;
    cursor: pointer;
    
}

.SongDisplay__Item__PlayListAdd:hover {
    color: rgb(255, 238, 0);
    transition: 0.3s ease;
}

.SongDisplay__Item__PlayListAdd > * {
    font-size: 1.4em;
    margin-top: .1em;
    /* align-content: center; */
}


.SongDisplay__Item__Round {
    min-width: 2em;
    font-weight: bold;
    margin-right: 0.3rem;
    /* border-right: 1px solid #ccc; */
    /* background: rgba(0,0,0, 0.2); */
}

.SongDisplay__Item__Name {
    min-width: 40%;
    max-width: 40%;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
    /* overflow-x: scroll; */
    
}

.SongDisplay__Item__TrackVotes {
    min-width: 1em;
    /* background: rgba(0,0,0, 0.2); */
    /* border-left: 1px solid #ccc;
    border-right: 1px solid #ccc; */
    /* align-self: c; */
    text-align: center;
}

.SongDisplay__Item__Musician {
    padding-left: .3rem;
    /* padding-right: .44rem; */
    width: 15%;
    overflow-x: hidden;
   
}

.SongDisplay__Item__Lyricist {
    padding-left: .3rem;
    /* padding-right: .44rem; */
    width: 15%;
    overflow-x: hidden;
    
}

.SongDisplay__Item__Vocals {
    padding-left: .3rem;
    /* padding-right: .44rem; */
    width: 15%;
    overflow-x: hidden;
    
}

.SongDisplay__Item__TrackVotes {
    min-width: 1em;
    /* background: rgba(0,0,0, 0.2); */
    /* border-left: 1px solid #ccc;
    border-right: 1px solid #ccc; */
    /* align-self: c; */
    text-align: center;
}

.SongDisplay__Item__MusicVotes {
    min-width: 1em;
    text-align: center;

}

.SongDisplay__Item__LyricVotes {
    min-width: 1em;
    text-align: center;
}

.SongDisplay__Item__VocalVotes {
    min-width: 1em;
    text-align: center;

}

.SongDisplay__Item__TrackWin {
    color: rgb(180, 68, 255);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    min-width: 0.8em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    
}

.SongDisplay__Item__TrackWin > * {
    min-width: 0.8em;
    font-size: 0.8em;
}
.SongDisplay__Item__MusicWin {
    color: orange;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.SongDisplay__Item__LyricskWin {
    color: orange;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.SongDisplay__Item__VocalsWin {
    color: orange;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}


.MyLoader {
    margin-top: 6em;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-self: start;
    /* animation-name: fadeIn;
    animation-duration: 0.3s; */
}

.ProfileLink {
    text-decoration: none;
    color: rgb(175, 110, 228);
}
.ProfileLink:hover {
    color: rgb(187, 29, 187);
    transition: 0.3s ease;
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
label {
    /* color: pink; */
    /* margin-bottom: 1em; */
    /* border: 1px solid red;
    background: white;
    color:black;
    border-left: 2px solid #ccc;
    border-right: 2px solid #ccc;
    border-top: 2px solid #ccc; */
    /* padding-right: 2em; */
}

input {
    /* min-width: 10em; */
    /* font-family: Arial, Helvetica, sans-serif; */
    margin-top: 0.17em;
    padding-left: 1em;
    font-size: 1em;
    height: 3em;
    min-width: 40em;
    border-radius: 0.3em;
    border: 2px solid #ccc;
}

input[type="file"] {
    /* min-width: 10em; */
    /* font-family: Arial, Helvetica, sans-serif; */
    /* background: #f1f1f1; */
    /* color: #1f1f1f; */
    /* margin-top: 2em; */
    /* visibility: hidden; */
    /* border: none; */
}
input[type="file"]:focus {
    /* min-width: 10em; */
    /* font-family: Arial, Helvetica, sans-serif; */
    /* background: #f1f1f1; */
    /* color: #1f1f1f; */
    /* visibility: hidden; */
    border: none;
}



textarea {
    margin-top: 0.17em;
    min-width: 40em;
    border-radius: 0.3em;
    border: 2px solid rgba(0,0,0, 0.1);
    font-size: 1em;
    font-family: "Monserrat", Arial, Helvetica, sans-serif;

    min-height: 10em;

    padding: 0.5em 1em;

}

textarea:focus {
    outline: none;
    border: 2px solid rgb(238, 46, 255);
    transition: 0.5s ease;
}

.TextInput {
    margin-top: 1em;

}

.SubmitSongForm__FileInput::-webkit-file-upload-button {
    display: none;
}

.SubmitSongForm__FileInput {
    color: #1f1f1f;
    border: none;
}

.SubmitSongForm__FileInput::before {
    content: 'Upload an Audio File';
    display: inline-block;

    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    /* margin: 0.5em 0.3em; */
    background: #7c61f7;
    border: 2px solid #7c61f7;
    border-radius: 0.2em;
    /* display: block; */
    padding: 0.6em 1em;
    text-decoration: none;
    color: #f1f1f1;
    font-size: 1.1em;
    cursor: pointer;
    /* margin: inherit; */
    margin-left: -1em;
    /* font-family: 'Monserrat', Arial, Helvetica, sans-serif; */
}

.SubmitSongForm__FileInput:hover::before {
    /* color: black; */
    /* width: 700px; */
    /* width: 500px; */
    /* transition: 1.0s ease; */
    background: rgb(238, 46, 255);
    border: 2px solid rgb(209, 43, 224);
    transition: 0.3s ease;
}


.TextInput__FileLabel {
    margin-top: 0.5em;
    display: block;
    /* border: 1px solid red; */
}
.SubmitSong {
    max-width: 1000px;
    /* background: rgba(0,0,0,0.5); */
    /* margin-top: 5em; */
    border-left: 3px solid rgba(0,0,0,0.5);
    border-right: 3px solid rgba(0,0,0,0.5);
    border-bottom: 3px solid rgba(0,0,0,0.5);
    background: rgba(0,0,0,0.5);
    /* border-radius: 1em; */
    font-size: 0.9em;
    margin: 5.5em auto 0 auto;
    display: flex;
    flex-flow: column nowrap;
    height: calc(100vh - 5.5em);
}

.SubmitSong > form > h2 {
    /* display: block;
    border: 1px solid red; */
    text-align: center;
    /* color: white; */
}
.SubmitSong  > h4 {
    /* display: block;
    border: 1px solid red; */
    text-align: center;
    /* color: white; */
}

.SubmitSong__Formbox {
    display: flex;
    flex-flow: column nowrap;
    /* justify-content: center; */
    align-items: center;
    /* border: 1px solid red; */
}

.SubmitSong__Formbox__Button {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    margin: 0.5em 0.3em;
    background: #7c61f7;
    border: 2px solid #7c61f7;
    border-radius: 0.2em;
    display: block;
    padding: 0.6em 2em;
    text-decoration: none;
    color: white;
    font-size: 1.1em;
    cursor: pointer;
   
}

.SubmitSong__Formbox__Button:hover {
    background: rgb(238, 46, 255);
    border: 2px solid rgb(209, 43, 224);
    transition: 0.1s ease;

}

.SubmitSong__SubmitInfo {
    width: 40em;
    color: white;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    padding: 0 0 1em 1em;
    background: rgb(67, 158, 248);
    border-radius: 0.5em;
    margin: 0.4em auto;
    transition: 0.5s ease;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border: 1px solid red; */
    align-self: center;
}

.SubmitSong__SubmitInfo__List {
    /* float: left; */
    padding-top: 1em;
    /* border: 1px solid red; */
    /* padding: 0; */
    
}

ul {
    padding-left: 1.1em;
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    /* pa */
    /* margin-left: 0px; */
}
li {
    padding-left: 0px;
    margin-left: 0px;
}

.SubmitSong__SubmitInfo__exit {

    line-height: 0.8em;
    padding-right: 0.07em;

    font-size: 2.5em;
    cursor: pointer;
    /* vertical-align: top; */
}

.SubmitSong__SubmitSuccess {
    width: 40em;
    color: white;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    /* padding: 1em; */
    padding: 0 0 1em 1em;
    background: rgb(5, 175, 5);
    border-radius: 0.5em;
    margin: 0.4em auto;
    transition: 0.5s ease;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-self: center;
}
.SubmitSong__SubmitSuccess__Message {
    /* float: left; */
    /* border: 1px solid red; */
    /* padding: 0; */
    padding-top: 1em;
    
}

.UploadProgress {
    width: 40em;
    /* margin: 8em auto 0 auto; */
    background: #7c61f7;
    padding: 0.5em;
    border-radius: 0.5em;
    /* z-index: 4;
    top: 33vh;
    position: absolute; */

    margin: 0 auto;

    /* left: 13em; */
    /* margin-left: -50px; */
    /* top: 50%; */
    /* margin-top: -50px; */
    /* margin: 0 auto; */
    /* left: calc(21em); */
}

.SubmitSong__SubmitSuccess__exit {
    line-height: 0.8em;
    padding-right: 0.07em;

    font-size: 2.5em;
    cursor: pointer;
    /* vertical-align: top; */
}

.veil {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    /* background: rgba(0,0,0,0.1); */
}

@-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

.MuiFilledInput-root {
    background: white !important;
  }

  .UserFormFlex {
      display: flex;
      flex-flow: row nowrap;
      
  }
.AdminSongDisplayContainer {
    display: flex;
    flex-flow: column nowrap;
    /* max-width: 1000px; */
    /* width: 100%; */
    /* max-width: 1200px; */
    /* border: 1px solid red; */
    /* border-right: 4px red; */
    /* max-width: 1100px; */
    /* height: calc(100vh - 5.5em); */
    /* overflow-y: scroll; */
    /* animation-name: fadeInRight; 
    animation-duration: 1.0s; */
    /* transition: 0.5s ease; */
}

.AdminSongDisplay__Titlebar {
    display: flex;
    flex-flow: row nowrap;
    color: #f1f1f1;
    text-transform: uppercase;
    /* font-size: 12px; */
    background: rgba(0,0,0,0.8);
    border-bottom: 1px solid rgba(255,255,255, 0.4);
    /* animation-name: fadeInDown; 
    animation-duration: 1.0s; */
    /* transition: 0.3s ease; */
}

.AdminSongDisplay__Titlebar__Round {
    min-width: 2em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    /* padding-left: 0.2em; */
    text-align: center;
    /* background: red; */
}

.AdminSongDisplay__Titlebar__Name {
    min-width: 2em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    /* padding-left: 0.2em; */
    /* text-align: center; */
    /* background: red; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    min-width: 25%;
    max-width: 25%; 
}

.AdminSongDisplay__Titlebar__Musician {
    /* min-width: 4em; */
    /* color: black; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    /* background: rgba(0,0,0,0.7); */
    /* color: lightblue; */
    /* border-left: 1px solid #ccc; */
    min-width: 14em;
    max-width: 14em;
    /* overflow-x: hidden; */
    /* border-bottom: 1px solid rgba(0,0,0,0.3); */
}

.AdminSongDisplay__Titlebar__Lyricist {
    /* min-width: 4em; */
    /* color: black; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;

    min-width: 14em;
    max-width: 14em;

}

.AdminSongDisplay__Titlebar__Vocalist {
    /* min-width: 4em; */
    /* color: black; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;

    min-width: 14em;
    max-width: 14em;

}

.AdminSongDisplay__Titlebar__Vote {
    background: rgba(0,0,0,0.3);
    color: #f1f1f1;
    min-width: 2em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    /* border-right: 1px solid rgba(0,0,0,0.3);
    border-bottom: 1px solid rgba(0,0,0,0.3); */
    text-align: center;
}
.AdminSongDisplay__Titlebar__Delete {
    background: rgba(0,0,0,0.3);
    color: #f1f1f1;
    min-width: 6em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    /* border-right: 1px solid rgba(0,0,0,0.3);
    border-bottom: 1px solid rgba(0,0,0,0.3); */
    text-align: center;
}
.AdminSongDisplay__Item {
    /* border-bottom: 1px solid #ccc; */
    
    display: flex;
    flex-flow: row wrap;
    /* border-right: 1px solid red; */
    /* border-bottom: 1px solid rgba(0,0,0,0.3); */
    /* max-width: 1100px; */
    overflow-x: hidden;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;


}

.AdminSongDisplay__Item:hover {

    background: rgb(204, 19, 204);
    /* border-bottom: 1px solid rgb(204, 19, 204); */
    /* border-left: 5px solid rgb(204, 19, 204); */
    /* border-bottom: 1px solid rgba(0,0,0,0.3); */

    transition: 0.1s ease;
    /* border-bottom: 1px solid rgba(204, 19, 204, 0.9); */

}
.AdminSongDisplay__Item:hover > * {

    /* background: rgba(204, 19, 204, 0.45); */
    /* background: rgb(59, 1, 59); */
    /* border-bottom: 1px solid rgb(59, 1, 59); ; */
    /* border-left: 5px solid rgb(204, 19, 204); */
    /* border-bottom: 1px solid rgba(0,0,0,0.3); */

    /* transition: 0.1s ease; */
    /* border-bottom: 1px solid rgba(204, 19, 204, 0.9); */

}

.AdminSongDisplay__Item__Round {
    min-width: 2em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    /* padding-left: 0.1em; */
    /* padding-right: 0.4em; */
    text-align: center;
    color: #f1f1f1;
    background: rgba(0,0,0,0.7);

    border-bottom: 1px solid rgba(0,0,0,0.3);
    /* border-left: 1px solid #ccc; */
}

.AdminSongDisplay__Item__Name {
    /* min-width: 4em; */
    /* color: black; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    color: rgb(204, 19, 204);
    background: rgba(0,0,0,0.8);
    border-bottom: 1px solid rgba(0,0,0,0.3);
    /* border-left: 1px solid rgba(0,0,0,0.3); */
    /* border-left: 1px solid #ccc; */
    /* min-width: 40%; */
    min-width: 25%;
     max-width: 25%; 

    /* min-width: 400px; */
    /* max-width: 400px; */
    /* overflow-x: hidden; */
    /* width: 40%; */
}

.AdminSongDisplay__Item__Name > a {
    color: inherit;
    text-decoration: none;
}
.AdminSongDisplay__Item__Musician {
    /* min-width: 4em; */
    /* color: black; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    background: rgba(0,0,0,0.7);
    color: lightblue;
    /* border-left: 1px solid #ccc; */
    min-width: 14em;
    max-width: 14em;
    overflow-x: hidden;
    border-bottom: 1px solid rgba(0,0,0,0.3);
}
.AdminSongDisplay__Item__Lyricist {
    /* min-width: 4em; */
    /* color: black; */
    color: lightblue;
    /* border-left: 1px solid #ccc; */
    background: rgba(0,0,0,0.8);
    /* min-width: 30%; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    min-width: 14em;
    max-width: 14em;
    overflow-x: hidden;
    border-bottom: 1px solid rgba(0,0,0,0.3);
}
.AdminSongDisplay__Item__Vocalist {
    /* min-width: 4em; */
    /* color: black; */
    background: rgba(0,0,0,0.7);
    color: lightblue;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    min-width: 14em;
    max-width: 14em;
    overflow-x: hidden;
    border-bottom: 1px solid rgba(0,0,0,0.3);
}

.AdminSongDisplay__Item__Vote {
    background: rgba(0,0,0,0.3);
    color: #f1f1f1;
    min-width: 2em;
    border-right: 1px solid rgba(0,0,0,0.3);
    border-bottom: 1px solid rgba(0,0,0,0.3);
    text-align: center;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
}

.AdminSongDisplay__Item__Delete {
    background: rgba(0,0,0,0.5);
    color: #f1f1f1;
    /* font-size: 1em; */
    /* font-size: 2em; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    min-width: 6em;
    border-right: 1px solid rgba(0,0,0,0.3);
    border-bottom: 1px solid rgba(0,0,0,0.3);
    text-align: center;
    cursor: pointer;

    /* border: 1px solid red; */
}

.AdminSongDisplay__Item__Delete > a {
    color: red;
    /* font-size: 1.0em; */
}

.AdminSongDisplay__Item__Delete:hover {
    background: #8e0000;
    transition: 0.3s ease;
}

.AdminSongDisplay__MessageSuccess {
    background: purple;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: white;
    padding-left: 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    -webkit-animation-name: fadeInRight;
            animation-name: fadeInRight;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    /* transition: 0.3s ease; */
    /* max-width: 1400px; */
}

.AdminSongDisplay__MessageSuccess__X {
    font-size: 2em;
    /* border: 1px solid black; */
    line-height: 0.5em;
    padding-right: 0.5em;
    cursor: pointer;
}

.AdminSongDisplay__MessageSuccess__Message {
    /* border: 1px solid black; */
    font-size: 1em;
}

.leaving {
    -webkit-animation-name: fadeOutRight;
            animation-name: fadeOutRight;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}

@-webkit-keyframes fadeInRight {
    0% {
       opacity: 0;
       transform: translateX(+100%);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 }

@keyframes fadeInRight {
    0% {
       opacity: 0;
       transform: translateX(+100%);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 } 

 @-webkit-keyframes fadeOutRight {
    0% {
       opacity: 1;
       transform: translateX(0);
    }
    100% {
       opacity: 0;
       transform: translateX(+100%);
    }
 } 

 @keyframes fadeOutRight {
    0% {
       opacity: 1;
       transform: translateX(0);
    }
    100% {
       opacity: 0;
       transform: translateX(+100%);
    }
 } 

 @media screen and (max-width: 1550px) {
    .AdminSongDisplay__Titlebar__Vote {
        display: none;
    }
    .AdminSongDisplay__Item__Vote {
        display: none;
    }
 }

 @media screen and (max-width: 1300px)
 {
    .AdminSongDisplay__Titlebar__Name {
        max-width: 20%;
        min-width: 20%;
    }
    .AdminSongDisplay__Item__Name {
        max-width: 20%;
        min-width: 20%;
    }
    .AdminSongDisplay__Titlebar__Musician {
        min-width: 10em;
        max-width: 10em;
    }
    .AdminSongDisplay__Item__Musician {
        min-width: 10em;
        max-width: 10em;
    }
    .AdminSongDisplay__Titlebar__Lyricist {
        min-width: 10em;
        max-width: 10em;
    }
    .AdminSongDisplay__Item__Lyricist {
        min-width: 10em;
        max-width: 10em;
    }
    .AdminSongDisplay__Titlebar__Vocalist {
        min-width: 10em;
        max-width: 10em;
    }
    .AdminSongDisplay__Item__Vocalist {
        min-width: 10em;
        max-width: 10em;
    }

 }

 @media screen and (max-width: 1000px) {
    .Navbar__Flexbox__Left__Item {
        display: none;
    }
 }
 @media screen and (max-width: 900px)
 {
    .AdminSongDisplay__Titlebar__Name {
        /* display: none; */
        min-width: 85%;
    }
    .AdminSongDisplay__Item__Name {
        /* display: none; */
        min-width: 85%;
    }

    .AdminSongDisplay__Titlebar__Musician {
        display: none;
    }
    .AdminSongDisplay__Item__Musician {
        display: none;
    }
    .AdminSongDisplay__Titlebar__Lyricist {
        display: none;
    }
    .AdminSongDisplay__Item__Lyricist {
        display: none;
    }
    .AdminSongDisplay__Titlebar__Vocalist {
        display: none;
    }
    .AdminSongDisplay__Item__Vocalist {
        display: none;
    }
    .AdminMenuBar {
        display: none;
    }
    .AdminSongDisplayContainer {
        width: 100vw;
    }
    .AdminSongDisplay__Titlebar__Delete {
        min-width: 3em;
    }
    .AdminSongDisplay__Item__Delete {
        min-width: 3em;
    }

    .Navbar__Flexbox__Left__Item {
        display: none;
    }

 }

 @media screen and (max-width: 550px)
 {
    .AdminSongDisplay__Titlebar__Delete {
        min-width: 2em;
    }
    .AdminSongDisplay__Item__Delete {
        min-width: 2em;
    }

 }

.SongEditor {
    height: calc(100vh - 5.5em);
    /* background: red; */
    min-width: 1000px;
    padding-left: 1em;
}

.SongEditor > form > * > label {
    color: white;
}
.SongEditor >  form  > * > * > label {
    color: white;
}

.SongEditor > * > * > * > input {
    /* max-width: 2em !important; */
    /* color: red; */
    /* max-width: 30px !important; */
    /* font-size: 0.7em; */
    /* display: inline-block; */
    /* border: 1px solid red; */
}

.SongEditor__Votesbox {
    display: flex;
    flex-flow: row wrap;
    max-width: 40em;
    /* border: 1px solid red; */
    justify-content: space-between;
    /* align-content: center; */
    /* align-items: center; */
    /* align-self: center; */
}

.SongEditor > form > div > div  {
    max-width: 8em;
    text-align: center;
}

.SongEditor > form > div > div > div > input {
    min-width: 6em;
    max-width: 6em;
    text-align: center;
    /* margin: 0 0 */
     padding-left: 0;
    /* color: red; */
}

.SongEditor > form > div > div >  textarea {
    max-width: 40em;
}

.SongEditor > h3 {
    color: #f1f1f1;
}

.SongEditor__Button {
    /* text-align: right; */
    /* float: right; */
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    margin: 1.5em auto;
    background: #7c61f7;
    border: 2px solid #7c61f7;
    border-radius: 0.2em;
    display: block;
    padding: 0.6em 2em;
    text-decoration: none;
    color: white;
    font-size: 1.1em;
    cursor: pointer;
}

.SongEditor__Button:hover {
    background: rgb(238, 46, 255);
    border: 2px solid rgb(209, 43, 224);
    transition: 0.1s ease;

}

.SongEditor__Submit {
    /* border: 1px solid red; */
    width: 40em;
    /* text-align: center; */

}

.SubmitSong__SubmitSuccess {
    margin: 0.5em 0 0 0;
    padding-left: 1em;
}

.SubmitSong__SubmitInfo {
    margin: 0.5em 0 0 0;
    padding-left: 1em;
}
.SongDisplay {
    max-width: 1000px;
    /* background: rgba(0,0,0,0.5); */
   
    border-left: 3px solid rgba(0,0,0,0.5);
    border-right: 3px solid rgba(0,0,0,0.4);
    /* border-radius: 1em; */
    font-size: 0.9em;
    margin: 5.5em auto 0 auto;
    display: flex;
    flex-flow: column nowrap;
    /* animation-name: fadeIn;
    animation-duration: 0.5s; */
    /* transition: 0.5s ease; */
}
.SongDisplay__Item:nth-child(odd) {
    background: rgba(0,0,0,0.15);
}
.SongDisplay__Item:nth-child(even) {
    background: rgba(0,0,0,0.2);
}

.SongDisplay__Item {
    /* background: rgba(0,0,0,0.3); */
    display: flex;
    flex-flow: row nowrap;
    /* border-bottom: 1px solid #ccc; */
    /* background: rgba(0,0,0, 0.5); */
    align-items: center;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;

}
.SongDisplay__Item:hover {
    background: rgba(0,0,0,0.5);
    /* transition: 0.1s ease; */

}



.SongDisplay__Item > * {
    /* background-image: linear-gradient(to right, rgba(255,255,255, 0.06) 0%,rgba(255,255,255, 0) 100%); */
    /* color: white; */


}

.SongDisplay__Item__PlayIcon {
    /* max-height: 0.5em; */
    color: purple;
    background: rgba(0,0,0,0.2);
    /* align-items: center; */
    /* padding-right: 0.3rem; */
    /* border: 1px solid red; */
   
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    
}


.SongDisplay__Item__PlayIcon > * {
    font-size: 1.4em;
    margin-top: .1em;
    /* align-content: center; */
}
.SongDisplay__Item__PlayIcon:hover {
    color: rgb(168, 8, 168);
    transition: 0.3s ease;
}


.SongDisplay__Item__PlayListAdd {
    /* max-height: 0.5em; */
    color: orange;
    background: rgba(0,0,0,0.2);
    /* align-items: center; */
    /* padding-right: 0.3rem; */
    /* border: 1px solid red; */
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-right: .2em;
    cursor: pointer;
    
}

.SongDisplay__Item__PlayListAdd:hover {
    color: rgb(255, 238, 0);
    transition: 0.3s ease;
}

.SongDisplay__Item__PlayListAdd > * {
    font-size: 1.4em;
    margin-top: .1em;
    /* align-content: center; */
}


.SongDisplay__Item__Round {
    min-width: 2em;
    font-weight: bold;
    margin-right: 0.3rem;
    /* border-right: 1px solid #ccc; */
    /* background: rgba(0,0,0, 0.2); */
}

.SongDisplay__Item__Name {
    min-width: 40%;
    max-width: 40%;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
    /* overflow-x: scroll; */
    
}

.SongDisplay__Item__TrackVotes {
    min-width: 1em;
    /* background: rgba(0,0,0, 0.2); */
    /* border-left: 1px solid #ccc;
    border-right: 1px solid #ccc; */
    /* align-self: c; */
    text-align: center;
}

.SongDisplay__Item__Musician {
    padding-left: .3rem;
    /* padding-right: .44rem; */
    width: 15%;
    overflow-x: hidden;
   
}

.SongDisplay__Item__Lyricist {
    padding-left: .3rem;
    /* padding-right: .44rem; */
    width: 15%;
    overflow-x: hidden;
    
}

.SongDisplay__Item__Vocals {
    padding-left: .3rem;
    /* padding-right: .44rem; */
    width: 15%;
    overflow-x: hidden;
    
}

.SongDisplay__Item__TrackVotes {
    min-width: 1em;
    /* background: rgba(0,0,0, 0.2); */
    /* border-left: 1px solid #ccc;
    border-right: 1px solid #ccc; */
    /* align-self: c; */
    text-align: center;
}

.SongDisplay__Item__MusicVotes {
    min-width: 1em;
    text-align: center;

}

.SongDisplay__Item__LyricVotes {
    min-width: 1em;
    text-align: center;
}

.SongDisplay__Item__VocalVotes {
    min-width: 1em;
    text-align: center;

}

.SongDisplay__Item__TrackWin {
    color: rgb(180, 68, 255);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    min-width: 0.8em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    
}

.SongDisplay__Item__TrackWin > * {
    min-width: 0.8em;
    font-size: 0.8em;
}
.SongDisplay__Item__MusicWin {
    color: orange;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.SongDisplay__Item__LyricskWin {
    color: orange;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.SongDisplay__Item__VocalsWin {
    color: orange;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}


.MyLoader {
    margin-top: 6em;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-self: start;
    /* animation-name: fadeIn;
    animation-duration: 0.3s; */
}


@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
