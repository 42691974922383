@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.App {
  /* text-align: center; */
 
}

body {
  background: url('./img/blurryphones.jpg');
  background-position: -600px 00;
  background-size: auto 100%;
  background-attachment: fixed;
  color: #f1f1f1;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  /* background-color: black; */

}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1f1f1f; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


/************FORMERLY ADMIN.CSS */
* {
  box-sizing: border-box;
}

.AdminContainer {
  /* max-width: 1000px; */
  margin: 0 auto;
  background: #1f1f1f;
  margin-top: 5em;
  font-size: 1em;
  min-height: calc(100vh - 5em);
  color: black;
  display: flex;
  flex-flow: row nowrap;
}

.AdminMenuBar {
  max-width: 300px;
  width: 25vw;
  background: #212529;
  min-height: calc(100vh - 5em);
  color: #f1f1f1;
  border-right: 3px solid rgba(0,0,0,0.5)
  /* border: 1px solid red; */
}

.AdminMenuBar__Header {
  margin-top: 1em;
  /* text-align: center; */
  /* background: rgb(90, 89, 89); */
  /* font-family: Arial, Helvetica, sans-serif; */
  background: rgba(0,0,0,0.4);
  text-transform: uppercase;
  color: #ccc;
  font-weight: bold;
  border-top: 4px solid rgba(0,0,0,0.5);
  /* border-left: 4px solid rgba(0,0,0,0.5);
  border-right: 4px solid rgba(0,0,0,0.5); */
  /* border-bottom: 4px solid rgba(0,0,0,0.5); */
  font-size: 0.7em;
  padding: 1em;
  /* border: 1px solid red; */
}

.AdminMenuBar__Link {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  /* font-weight: bold; */
  background: rgba(0,0,0,0.1);
  font-size: 1em;
  padding: 1em 1em;
  border-left: 4px solid #ccc;

}

.AdminMenuBar__Link:hover {
  background: purple;
  transition: 0.3s ease;
  border-left: 4px solid rgb(90, 1, 90);
  padding-left: 20px;
  cursor: pointer;
  /* font-weight: bold; */

}

.AdminContainer > h3 {
  color: #f1f1f1;
  text-align: center;
  border: 1px solid red;
}

.Nope {
  text-align: center;
  margin-top: 5.5em;
}

.AdminMenuBar__Link > a {
  text-decoration: none;
  color: inherit;
}

.rc-slider-track {
    background: linear-gradient(to right, #ff3019 ,#cf0404);
}
.rc-slider-handle {
  background-color: #8e0000 !important ;
}

/*******************************/