.AdminSongDisplayContainer {
    display: flex;
    flex-flow: column nowrap;
    /* max-width: 1000px; */
    /* width: 100%; */
    /* max-width: 1200px; */
    /* border: 1px solid red; */
    /* border-right: 4px red; */
    /* max-width: 1100px; */
    /* height: calc(100vh - 5.5em); */
    /* overflow-y: scroll; */
    /* animation-name: fadeInRight; 
    animation-duration: 1.0s; */
    /* transition: 0.5s ease; */
}

.AdminSongDisplay__Titlebar {
    display: flex;
    flex-flow: row nowrap;
    color: #f1f1f1;
    text-transform: uppercase;
    /* font-size: 12px; */
    background: rgba(0,0,0,0.8);
    border-bottom: 1px solid rgba(255,255,255, 0.4);
    /* animation-name: fadeInDown; 
    animation-duration: 1.0s; */
    /* transition: 0.3s ease; */
}

.AdminSongDisplay__Titlebar__Round {
    min-width: 2em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    /* padding-left: 0.2em; */
    text-align: center;
    /* background: red; */
}

.AdminSongDisplay__Titlebar__Name {
    min-width: 2em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    /* padding-left: 0.2em; */
    /* text-align: center; */
    /* background: red; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    min-width: 25%;
    max-width: 25%; 
}

.AdminSongDisplay__Titlebar__Musician {
    /* min-width: 4em; */
    /* color: black; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    /* background: rgba(0,0,0,0.7); */
    /* color: lightblue; */
    /* border-left: 1px solid #ccc; */
    min-width: 14em;
    max-width: 14em;
    /* overflow-x: hidden; */
    /* border-bottom: 1px solid rgba(0,0,0,0.3); */
}

.AdminSongDisplay__Titlebar__Lyricist {
    /* min-width: 4em; */
    /* color: black; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;

    min-width: 14em;
    max-width: 14em;

}

.AdminSongDisplay__Titlebar__Vocalist {
    /* min-width: 4em; */
    /* color: black; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;

    min-width: 14em;
    max-width: 14em;

}

.AdminSongDisplay__Titlebar__Vote {
    background: rgba(0,0,0,0.3);
    color: #f1f1f1;
    min-width: 2em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    /* border-right: 1px solid rgba(0,0,0,0.3);
    border-bottom: 1px solid rgba(0,0,0,0.3); */
    text-align: center;
}
.AdminSongDisplay__Titlebar__Delete {
    background: rgba(0,0,0,0.3);
    color: #f1f1f1;
    min-width: 6em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    /* border-right: 1px solid rgba(0,0,0,0.3);
    border-bottom: 1px solid rgba(0,0,0,0.3); */
    text-align: center;
}
.AdminSongDisplay__Item {
    /* border-bottom: 1px solid #ccc; */
    
    display: flex;
    flex-flow: row wrap;
    /* border-right: 1px solid red; */
    /* border-bottom: 1px solid rgba(0,0,0,0.3); */
    /* max-width: 1100px; */
    overflow-x: hidden;
    animation-name: fadeIn;
    animation-duration: 0.5s;


}

.AdminSongDisplay__Item:hover {

    background: rgb(204, 19, 204);
    /* border-bottom: 1px solid rgb(204, 19, 204); */
    /* border-left: 5px solid rgb(204, 19, 204); */
    /* border-bottom: 1px solid rgba(0,0,0,0.3); */

    transition: 0.1s ease;
    /* border-bottom: 1px solid rgba(204, 19, 204, 0.9); */

}
.AdminSongDisplay__Item:hover > * {

    /* background: rgba(204, 19, 204, 0.45); */
    /* background: rgb(59, 1, 59); */
    /* border-bottom: 1px solid rgb(59, 1, 59); ; */
    /* border-left: 5px solid rgb(204, 19, 204); */
    /* border-bottom: 1px solid rgba(0,0,0,0.3); */

    /* transition: 0.1s ease; */
    /* border-bottom: 1px solid rgba(204, 19, 204, 0.9); */

}

.AdminSongDisplay__Item__Round {
    min-width: 2em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    /* padding-left: 0.1em; */
    /* padding-right: 0.4em; */
    text-align: center;
    color: #f1f1f1;
    background: rgba(0,0,0,0.7);

    border-bottom: 1px solid rgba(0,0,0,0.3);
    /* border-left: 1px solid #ccc; */
}

.AdminSongDisplay__Item__Name {
    /* min-width: 4em; */
    /* color: black; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    color: rgb(204, 19, 204);
    background: rgba(0,0,0,0.8);
    border-bottom: 1px solid rgba(0,0,0,0.3);
    /* border-left: 1px solid rgba(0,0,0,0.3); */
    /* border-left: 1px solid #ccc; */
    /* min-width: 40%; */
    min-width: 25%;
     max-width: 25%; 

    /* min-width: 400px; */
    /* max-width: 400px; */
    /* overflow-x: hidden; */
    /* width: 40%; */
}

.AdminSongDisplay__Item__Name > a {
    color: inherit;
    text-decoration: none;
}
.AdminSongDisplay__Item__Musician {
    /* min-width: 4em; */
    /* color: black; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    background: rgba(0,0,0,0.7);
    color: lightblue;
    /* border-left: 1px solid #ccc; */
    min-width: 14em;
    max-width: 14em;
    overflow-x: hidden;
    border-bottom: 1px solid rgba(0,0,0,0.3);
}
.AdminSongDisplay__Item__Lyricist {
    /* min-width: 4em; */
    /* color: black; */
    color: lightblue;
    /* border-left: 1px solid #ccc; */
    background: rgba(0,0,0,0.8);
    /* min-width: 30%; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    min-width: 14em;
    max-width: 14em;
    overflow-x: hidden;
    border-bottom: 1px solid rgba(0,0,0,0.3);
}
.AdminSongDisplay__Item__Vocalist {
    /* min-width: 4em; */
    /* color: black; */
    background: rgba(0,0,0,0.7);
    color: lightblue;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    min-width: 14em;
    max-width: 14em;
    overflow-x: hidden;
    border-bottom: 1px solid rgba(0,0,0,0.3);
}

.AdminSongDisplay__Item__Vote {
    background: rgba(0,0,0,0.3);
    color: #f1f1f1;
    min-width: 2em;
    border-right: 1px solid rgba(0,0,0,0.3);
    border-bottom: 1px solid rgba(0,0,0,0.3);
    text-align: center;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
}

.AdminSongDisplay__Item__Delete {
    background: rgba(0,0,0,0.5);
    color: #f1f1f1;
    /* font-size: 1em; */
    /* font-size: 2em; */
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    min-width: 6em;
    border-right: 1px solid rgba(0,0,0,0.3);
    border-bottom: 1px solid rgba(0,0,0,0.3);
    text-align: center;
    cursor: pointer;

    /* border: 1px solid red; */
}

.AdminSongDisplay__Item__Delete > a {
    color: red;
    /* font-size: 1.0em; */
}

.AdminSongDisplay__Item__Delete:hover {
    background: #8e0000;
    transition: 0.3s ease;
}

.AdminSongDisplay__MessageSuccess {
    background: purple;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: white;
    padding-left: 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    animation-name: fadeInRight;
    animation-duration: 0.5s;
    /* transition: 0.3s ease; */
    /* max-width: 1400px; */
}

.AdminSongDisplay__MessageSuccess__X {
    font-size: 2em;
    /* border: 1px solid black; */
    line-height: 0.5em;
    padding-right: 0.5em;
    cursor: pointer;
}

.AdminSongDisplay__MessageSuccess__Message {
    /* border: 1px solid black; */
    font-size: 1em;
}

.leaving {
    animation-name: fadeOutRight;
    animation-duration: 0.5s;
}

@keyframes fadeInRight {
    0% {
       opacity: 0;
       transform: translateX(+100%);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 } 

 @keyframes fadeOutRight {
    0% {
       opacity: 1;
       transform: translateX(0);
    }
    100% {
       opacity: 0;
       transform: translateX(+100%);
    }
 } 

 @media screen and (max-width: 1550px) {
    .AdminSongDisplay__Titlebar__Vote {
        display: none;
    }
    .AdminSongDisplay__Item__Vote {
        display: none;
    }
 }

 @media screen and (max-width: 1300px)
 {
    .AdminSongDisplay__Titlebar__Name {
        max-width: 20%;
        min-width: 20%;
    }
    .AdminSongDisplay__Item__Name {
        max-width: 20%;
        min-width: 20%;
    }
    .AdminSongDisplay__Titlebar__Musician {
        min-width: 10em;
        max-width: 10em;
    }
    .AdminSongDisplay__Item__Musician {
        min-width: 10em;
        max-width: 10em;
    }
    .AdminSongDisplay__Titlebar__Lyricist {
        min-width: 10em;
        max-width: 10em;
    }
    .AdminSongDisplay__Item__Lyricist {
        min-width: 10em;
        max-width: 10em;
    }
    .AdminSongDisplay__Titlebar__Vocalist {
        min-width: 10em;
        max-width: 10em;
    }
    .AdminSongDisplay__Item__Vocalist {
        min-width: 10em;
        max-width: 10em;
    }

 }

 @media screen and (max-width: 1000px) {
    .Navbar__Flexbox__Left__Item {
        display: none;
    }
 }
 @media screen and (max-width: 900px)
 {
    .AdminSongDisplay__Titlebar__Name {
        /* display: none; */
        min-width: 85%;
    }
    .AdminSongDisplay__Item__Name {
        /* display: none; */
        min-width: 85%;
    }

    .AdminSongDisplay__Titlebar__Musician {
        display: none;
    }
    .AdminSongDisplay__Item__Musician {
        display: none;
    }
    .AdminSongDisplay__Titlebar__Lyricist {
        display: none;
    }
    .AdminSongDisplay__Item__Lyricist {
        display: none;
    }
    .AdminSongDisplay__Titlebar__Vocalist {
        display: none;
    }
    .AdminSongDisplay__Item__Vocalist {
        display: none;
    }
    .AdminMenuBar {
        display: none;
    }
    .AdminSongDisplayContainer {
        width: 100vw;
    }
    .AdminSongDisplay__Titlebar__Delete {
        min-width: 3em;
    }
    .AdminSongDisplay__Item__Delete {
        min-width: 3em;
    }

    .Navbar__Flexbox__Left__Item {
        display: none;
    }

 }

 @media screen and (max-width: 550px)
 {
    .AdminSongDisplay__Titlebar__Delete {
        min-width: 2em;
    }
    .AdminSongDisplay__Item__Delete {
        min-width: 2em;
    }

 }
